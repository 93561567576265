<template>
  <SubPageWrapper>
    <template #left-col>
      <SlTabList
        v-if="settingTabs.length"
        v-model="tabModel"
        :tabs="settingTabs"
      />
      <SlTabList
        v-model="tabModel"
        :tabs="importTabs"
      >
        <template
          v-if="!isAggregation"
          #header
        >
          {{ $t('Web.Integrations.TitleImport') }}
        </template>
      </SlTabList>
    </template>
    <slot name="header" />
    <ContentBlock>
      <SlTabContent
        v-for="tab in renderedTabs"
        :key="tab.value"
        :value="tab.value"
        :tab-value="tabModel"
      >
        <ValidationObserver
          :ref="`${tab.value}-observer`"
          tag="form"
          @submit.prevent.stop="handleTabSubmit"
        >
          <component
            :is="tab.component"
            :title="tab.label"
            :tab="tab.value"
            :stubs="tabStubs"
          />
        </ValidationObserver>
        <TablePreview :tab="tabModel" />
      </SlTabContent>
    </ContentBlock>
    <template #right-col>
      <TablesInfo
        v-if="tables.length"
        :tables="tables"
        :is-integration="!isAggregation"
      />
    </template>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import IntegrationContent from '@/components/Integrations/IntegrationContent.vue';
import TablesInfo from '@/components/Integrations/Modules/Transform/TablesInfo.vue';
import TablePreview from '@/components/Integrations/Modules/Transform/TablePreview.vue';
import Transactions from '@/components/Integrations/Modules/Transform/Import/Tabs/Transactions.vue';
import OrdersToReceive from '@/components/Integrations/Modules/Transform/Import/Tabs/OrdersToReceive.vue';
import BaseTab from '@/components/Integrations/Modules/Transform/Import/Tabs/BaseTab.vue';
import Settings from '@/components/Integrations/Modules/Transform/Import/Tabs/Settings.vue';
import { fileSaver } from '@/mixins/webAPI';
import { transformValidation } from '@/mixins/integrations/transform/transformValidation.mixin';
import { transformShared } from '@/mixins/integrations/transform/transformShared.mixin';
import { importTabs, importSettingTabs } from '@/config/integrations/transform.config';

export default {
  name: 'ImportTransform',
  components: {
    IntegrationContent,
    TablePreview,
    TablesInfo,
    // tabs
    Transactions,
    OrdersToReceive,
    BaseTab,
    Settings
  },
  mixins: [fileSaver, transformValidation, transformShared],
  props: {
    requiredTabKeys: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      tabModel: null
    };
  },
  computed: {
    ...mapState({
      activeIntegration: state => state.integrations.active_integration || {},
      transformState: state => state.integrations.transform.import
    }),
    settingTabs() {
      return importSettingTabs(this, this.activeIntegration);
    },
    importTabs() {
      return importTabs(this, this.activeIntegration).map(tab => ({
        ...tab,
        required: this.requiredTabKeys.includes(tab.value)
      }));
    },
    renderedTabs() {
      return [
        ...this.settingTabs,
        ...this.importTabs
      ];
    }
  },
  async created() {
    try {
      this.setIsLoading(true);

      await Promise.allSettled([
        this.fetchTransformSettings(),
        this.fetchSlots(),
        this.fetchTables()
      ]);
    } finally {
      this.setIsLoading(false);
    }
  },
  methods: {
    ...mapActions({
      fetchTransformSettings: 'integrations/transform/fetchTransformSettings',
      fetchSlots: 'integrations/transform/import/fetchSlots',
      fetchTables: 'integrations/transform/fetchTables',
      fetchTablePreview: 'integrations/transform/fetchTablePreview',
      confirmTransformation: 'integrations/transform/confirmTransformation',
      exportToCSV: 'integrations/transform/import/exportToCSV',
      downloadCsv: 'integrations/transform/import/downloadCsv',
      updateIntegrationSettings: 'integrations/updateIntegrationSettings',
      updateTabSettings: 'integrations/transform/import/updateTabSettings',
      subscribe: 'operations/subscribe'
    }),
    setSettings(payload) {
      this.updateTabSettings({
        tab: this.tabModel,
        ...payload
      });
    },
    getQueryPayload() {
      return {
        tab: this.tabModel
      };
    },
    // actions
    async handleTabSubmit(e) {
      const valid = await this.$sl_validateActiveTab();

      if (!valid) {
        return;
      }

      if (e.submitter.hasAttribute(['data-preview'])) {
        return this.handleTablePreview();
      }

      if (e.submitter.hasAttribute(['data-export'])) {
        return this.handleExportToCSV();
      }

    },
    async handleTablePreview() {
      if (this.$sl_requiredSettingsTabs.length) {
        const currentTab = this.tabModel;
        const isSettingsValid = await this.$sl_validateRequiredSettingsTab();

        if (!isSettingsValid) {
          return;
        }

        this.tabModel = currentTab;
        await this.updateIntegrationSettings();
      }

      return this.fetchTablePreview(this.getQueryPayload());
    },
    async handleExportToCSV() {
      try {
        const { operationData } = await this.subscribe({
          subscriber: () => this.exportToCSV(this.getQueryPayload())
        });

        this.saveFile(
          this.downloadCsv.bind(this, { fileId: operationData.id }),
          {
            errorTitle: this.$t('Error.HeadDb'),
            notifyType: 'notify'
          }
        );
      } catch (e) {
        const errorMessage = e?.message;

        if (errorMessage) {
          this.$notify({
            type: 'error',
            text: errorMessage,
            duration: 10000
          });
        }
      }
    },
    async finish() {
      try {
        this.setIsLoading(true);

        const valid = await this.$sl_validateFilledTabs();

        if (!valid) {
          return;
        }

        const success = await this.confirmTransformation();

        return success;
      } finally {
        this.setIsLoading(false);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .content-block__main {
    height: 100%;

    .sl-tab-content {
      inset: 24px;

      height: fit-content;
      overflow: visible;
    }
  }
}
</style>
